
import { NgModule } from '@angular/core';
import { SharedModule } from '../_common/shared.module';
import { EditProfileComponent } from './edit-profile.component';

@NgModule({
  declarations: [
    EditProfileComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    EditProfileComponent
  ]
})

export class EditProfileModule { }
