// Angular Library Imports
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
  public isNoMuduleAccess: boolean = true;
  panels = [];

  constructor(private commonService: CommonService) {

  }
  ngOnInit() {
    this.panels = [
      {
        active: true,
        name: 'This is panel header 1',
        childPanel: [
          {
            active: false,
            name: 'This is panel header 1-1'
          }
        ]
      },
      {
        active: false,
        name: 'This is panel header 2'
      },
      {
        active: false,
        name: 'This is panel header 3'
      }
    ];
    this.commonService.eventObservable.subscribe(response => {
      if (response['event'] === 'isNoMuduleAccess') {
        this.isNoMuduleAccess = response['data'];
      }
    })
    // this.isNoMuduleAccess = Boolean(localStorage.getItem('isNoMuduleAccess'));  
  }
}
