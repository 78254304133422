import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tmstermscondition',
  templateUrl: './tmstermscondition.component.html',
  styleUrls: ['./tmstermscondition.component.scss']
})
export class TmstermsconditionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
