import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonPipe } from '../_pipes/common.pipe';
import { AutofocusDirective } from '../_directives/common.directive';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzProgressModule } from 'ng-zorro-antd/progress';


import {
    NzGridModule,
    NzIconModule,
    NzBreadCrumbModule,
    NzListModule,
    NzFormModule,
    NzCheckboxModule,
    NzPopoverModule,
    NzStatisticModule,
    NzCardModule,
    NzCascaderModule,
    NzTagModule,
    NzSwitchModule,
    NzSkeletonModule,
    NzTabsModule,
    NzUploadModule,
    NzInputNumberModule,
    NzModalModule,
    NzAvatarModule,
    NzButtonModule,
    NzInputModule,
    NzDropDownModule,
    NzPageHeaderModule,
    NzToolTipModule,
    NzTableModule,
    NzSpinModule,
    NzPopconfirmModule,
    NzSelectModule,
    NzDatePickerModule,
    NzDividerModule,
    NzBadgeModule,
    NzDescriptionsModule,
    NzNotificationModule,
    NzRadioModule,
    NzCollapseModule,
    NzPaginationModule
  } from 'ng-zorro-antd';
import { TableLayoutComponent } from './table-layout/table-layout.component';
import { FormatCellPipe, TruncatePipe } from './format-cell.pipe';
import { ViewTemplateComponent } from './view-template/view-template.component';
import { WelcomeComponent } from './welcome/welcome.component';


const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, ScrollingModule];

const NZ_MODULES = [
      NzPaginationModule,
      NzGridModule,
      NzIconModule,
      NzBreadCrumbModule,
      NzListModule,
      NzFormModule,
      NzCheckboxModule,
      NzPopoverModule,
      NzStatisticModule,
      NzCardModule,
      NzCascaderModule,
      NzTagModule,
      NzSwitchModule,
      NzSkeletonModule,
      NzTabsModule,
      NzUploadModule,
      NzInputNumberModule,
      NzModalModule,
      NzAvatarModule,
      NzDropDownModule,
      NzInputModule,
      NzToolTipModule,
      NzTableModule,
      NzSpinModule,
      NzProgressModule,
      NzPopconfirmModule,
      NzSelectModule,
      NzDatePickerModule,
      NzDividerModule,
      NzBadgeModule,
      NzPageHeaderModule,
      NzDescriptionsModule,
      NzButtonModule,
      NzNotificationModule,
      NzRadioModule,
      NzAutocompleteModule,
      NzCollapseModule
    ];

  const PIPES = [CommonPipe, FormatCellPipe, TruncatePipe];
  const DIRECTIVES = [AutofocusDirective]
  const SHARED_COMPONENTS = [TableLayoutComponent, ViewTemplateComponent, WelcomeComponent]
  @NgModule({
    declarations: [...PIPES, ...DIRECTIVES, ...SHARED_COMPONENTS],
    imports: [...BASE_MODULES, ...NZ_MODULES],
    exports: [...BASE_MODULES, ...NZ_MODULES, ...PIPES, ...DIRECTIVES, ...SHARED_COMPONENTS],
    // entryComponents: [...ENTRY_COMPONENTS],
  })

  export class SharedModule {}
