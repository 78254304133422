import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TableLayoutService {

  getTableData(url , dataParams?:any): Observable<{}> {
    return this.http.get(url , {
      params: dataParams
    });
  }

  getAutocompleteData(url, dataParams): Observable<{}> {
    return this.http.get(url , {
      params: dataParams
    });
  }
  
  updateStatus(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  notify(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  publish(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }
  
  updateTableData(url, statusParams : any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  deleteData(url, statusParams: any): Observable<{}> {
    return this.http.put(url , statusParams);
  }

  constructor(private http: HttpClient) {}
}
