import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { formatDate } from '@angular/common';
// import { CurrencyPipe } from '@angular/common';
@Pipe({ name: 'formatCell' })
export class FormatCellPipe implements PipeTransform {
    constructor () { }
    transform(value: any) {
        if ( value === undefined ) {
            return 'not available';
        }
        // if ( format ) {
            if ( Array.isArray(value) ) {
                if ( typeof value[0] !== 'object' ) {
                    return value.join(', ');
                } else {
                    return value.map( obj => {
                        return obj.role_name.substr(0,1).toLocaleUpperCase() + obj.role_name.substr(1)
                    }).join(', ');
                }
            }
            if ( typeof value === "object") {
                return value.role_name
            }
        // }

        // if (format === 'currency') {
        //     return this.currencyPipe.transform(value, 'USD', true);
        // }

        return value;
    }
}

@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string) {}
  transform(value: any, limit = 50, completeWords = false, ellipsis = '...') {
    if (isNaN(value) && Date.parse(value.replace(/ /g,''))) {
      const isDate = this.is_date(new Date(value));
      if(isDate) {
        return formatDate(value, 'yyyy-MM-dd', this.locale);
      }
    } else {
      value = value.toString();
       if (value.length < limit)
       return `${value.substr(0, limit)}`;

       if (completeWords) {
         limit = value.substr(0, limit).lastIndexOf(' ');
       }
       return `${value.substr(0, limit)}${ellipsis}`;
    }
}
private is_date(input) {
  // return d instanceof Date && !isNaN(d);
    if ( Object.prototype.toString.call(input) === "[object Date]" )  {
    if (isNaN(input.getTime())) {  // d.valueOf() could also work
      return false;
    } else {
      // date is valid
      return true;
    }
  }
}
}
