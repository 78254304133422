import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor, HttpErrorInterceptor } from './_helpers';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
/** config angular i18n **/
import { registerLocaleData, DatePipe } from '@angular/common';
import en from '@angular/common/locales/en';
import { NzLayoutModule, NzBackTopModule } from 'ng-zorro-antd';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
/** config ng-zorro-antd i18n **/
import { NZ_I18N, en_US } from 'ng-zorro-antd';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { DynamicBreacrumbModule } from './dynamic-breacrumb/dynamic-breacrumb.module';
import { DashboardModule } from './dashboard/dashboard.module';
import { UserSettingsPopupModule } from './user-settings-popup/user-settings-popup.module';
import { ChangePasswordModule } from './change-password/change-password.module';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SharedModule } from './_common/shared.module';
import { CanDeactivateGuard } from './_guards/can-deactivate-guard';
import { EditProfileModule } from './edit-profile/edit-profile.module';
import { CookieService } from 'ngx-cookie-service';
import { CommonService } from './_services/common.service';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { RegisterComponent } from './register/register.component';
import { ExportAsModule } from 'ngx-export-as';
import { TruckerCostComponent } from './trucker-cost/trucker-cost.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { LcltermsconditionComponent } from './lcltermscondition/lcltermscondition.component';
import { TmstermsconditionComponent } from './tmstermscondition/tmstermscondition.component';
import { EmailTruckerResultComponent } from './email-trucker-result/email-trucker-result.component';

registerLocaleData(en);

const PAGE_COMPONENTS = [
  AppComponent,
  LoginComponent,
  RegisterComponent,
  LayoutComponent,
  PageNotFoundComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  TruckerCostComponent,
  ThankyouComponent,
  LcltermsconditionComponent,
  TmstermsconditionComponent,
  EmailTruckerResultComponent
]

const PAGE_MODULES = [
  BrowserModule,
  LayoutModule,
  HttpClientModule,
  ExportAsModule,
  BrowserAnimationsModule,
  UserSettingsPopupModule,
  EditProfileModule,
  ChangePasswordModule,
  DynamicBreacrumbModule,
  DashboardModule,
  SharedModule,
  NzLayoutModule,
  NzBackTopModule,
  AppRoutingModule,
  NgZorroAntdModule,
  NzCollapseModule,
]
@NgModule({
  declarations: [...PAGE_COMPONENTS],
  imports: [...PAGE_MODULES],
   providers   : [
     CanDeactivateGuard,
     CookieService,
     CommonService,
     DatePipe,
     { provide: NZ_I18N, useValue: en_US },
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
     { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
