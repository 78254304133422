import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { NzNotificationService } from 'ng-zorro-antd';
// import { editProfileService } from './change-password.service';
import * as crypto from 'crypto-js';
import { SECRET_KEY } from '../_config/api.config';
import { EditProfileService } from './edit-profile.service';
import { CustomValidator } from '../_common/custom-validators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-edit-profile',
  providers: [EditProfileService],
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit, OnDestroy {
  // secret key to encrypt the login credentails
  private secretKey: string = SECRET_KEY;

  // managing subscription
  private subscription: Subscription = new Subscription();

  // submit loading
  public isLoadingOne = false;

  // disable field flag
  public isDisabled = true;

  // button label
  public buttonLbl = 'Edit';

  // form instance
  public validateForm: FormGroup;

  // form submit stauts
  public submitted: boolean = false;

  public userDetails: any = [];


  /**
   * function to reset form
   */
  public resetForm(): void {
    // e.preventDefault();
    this.validateForm.reset();
    for (const key in this.f) {
      this.f[key].markAsPristine();
      this.f[key].updateValueAndValidity();
    }
  }



  // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  /**
   * editForm
   */
  public editForm(isDisabled) {
    if(isDisabled) {
      this.isDisabled = false;
      this.buttonLbl = 'Cancel';
    } else {
      this.isDisabled = true;
      this.buttonLbl = 'Edit';
      // reset with pre-filled values of form
      this.validateForm.setValue({
        firstName: this.userDetails['first_name'] ? this.userDetails['first_name'] : '',
        lastName: this.userDetails['last_name'] ? this.userDetails['last_name'] : '',
        company_name: this.userDetails['company_name'] ? this.userDetails['company_name'] : '',
        contact_no: this.userDetails['phone_no'] ? this.userDetails['phone_no'] : '',
        additional_contact_no: this.userDetails['additional_phone'] ? this.userDetails['additional_phone'] : '',
        address: this.userDetails['address'] ? this.userDetails['address'] : ''
      })
    }
  }

  /**
   * function to dubmit the form
   */
  public submitForm(): void {
    for (const i in this.f) {
      this.f[i].markAsDirty();
      this.f[i].updateValueAndValidity();
    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.validateForm.invalid) {
      return;
    }
    // params send to change status
    const updateParams = {
      'first_name': this.f.firstName.value,
      'last_name': this.f.lastName.value,
      'company_name': this.f.company_name.value,
      'phone_no': this.f.contact_no.value,
      'address': this.f.address.value,
      'additional_phone': this.f.additional_contact_no.value
    };
    // submit loading
    this.isLoadingOne = true;
    // api call to update the status
    this.subscription.add(this.editProfileService
      .updateUserProfile(updateParams)
      .subscribe((response: any) => {
        // if success then go in loop
        if (response['code'] === 200 && response['data'] === true) {
          // success modal when status updated
          this.notification.success(
            'Profile Updated',
            'Your details has been saved successfully!'
          );
          this.isDisabled = true;
          this.buttonLbl = 'Edit';
          this.getProfileDetails();
          // this.router.navigateByUrl('/welcome');
        } else if (response['code'] === 200 && response['data']['isCustomerPhoneExists'] === true) {
          this.notification.error(
            'Error',
            response['message']
          );
        } else {
          this.notification.error(
            'Info',
            response['message']
          );
        }
        // submit loading
        this.isLoadingOne = false;
      }, (error) => {
        // submit loading
        this.isLoadingOne = false;
        // print the error on console
        console.error(error);
      }));
  }

  // error messages
  public validation_messages = {
    'firstName': [
			{ type: 'required', message: 'Please enter your first name.' },
			{ type: 'minlength', message: 'First Name must be at least 3 characters long.' },
			{ type: 'maxlength', message: 'First Name should not be more than 40 characters long.' },
			{ type: 'whitespace', message: 'Invalid first name' },
		],
		'lastName': [
			{ type: 'required', message: 'Please enter your last name.' },
			{ type: 'minlength', message: 'Last Name must be at least 3 characters long.' },
			{ type: 'maxlength', message: 'Last Name should not be more than 40 characters long.' },
			{ type: 'whitespace', message: 'Invalid last name' },
		],
    'contact_no': [
      { type: 'whitespace', message: 'Invalid contact number' },
      { type: 'required', message: 'Please enter contact number' },
      { type: 'minlength', message: 'Contact number should not be less than 10 numbers' },
      { type: 'maxlength', message: 'Contact number should not be more than 10 numbers' }
    ],
    'company_name': [
      { type: 'whitespace', message: 'Invalid company name' },
      { type: 'required', message: 'Please enter company name' },
      { type: 'maxlength', message: 'Company name should not be more than 40 characters' }
    ],
    'additional_contact_no': [
      { type: 'whitespace', message: 'Invalid contact number' },
      { type: 'minlength', message: 'Contact number should not be less than 10 numbers' },
      { type: 'maxlength', message: 'Contact number should not be more than 15 numbers' }
    ],
    'address': [
      { type: 'whitespace', message: 'Invalid address' },
      { type: 'maxlength', message: 'Address should not be more than 200 characters long' }
    ]
  }


  constructor(private fb: FormBuilder,
    private editProfileService: EditProfileService,
    private notification: NzNotificationService,
    private router: Router,
  ) {

    // form group instancec
    this.validateForm = this.fb.group({
      firstName: [null,
				{
					validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator,
					// Validators.minLength(3),
					Validators.maxLength(40)]),
					//updateOn: 'blur'
				}],
			lastName: [null,
				{
					validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator,
					// Validators.minLength(3),
					Validators.maxLength(40)]),
					//updateOn: 'blur'
				}],
      contact_no: new FormControl(null, {
        validators: Validators.compose([
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.required,
          CustomValidator.noWhiteSpaceValidator,
        ]),
      }),
      company_name: new FormControl(null, {
        validators: Validators.compose([
          Validators.maxLength(40),
          Validators.required,
          CustomValidator.noWhiteSpaceValidator,
        ]),
      }),
      additional_contact_no: new FormControl(null, {
        validators: Validators.compose([
          Validators.minLength(10),
          Validators.maxLength(10),
          CustomValidator.noWhiteSpaceValidator,
        ]),
      }),
      address: new FormControl(null, {
        validators: Validators.compose([
          Validators.maxLength(200),
          CustomValidator.noWhiteSpaceValidator,
        ]),
      }),
    });
  }

  ngOnInit() {
    this.getProfileDetails();
  }

  public getProfileDetails() {
    this.subscription.add(this.editProfileService
      .getUserProfileDetails()
      .subscribe((response: any) => {
        // if success then go in loop
        if (response['code'] === 200) {
          this.userDetails = response['data'];
          this.validateForm.setValue({
            firstName: this.userDetails['first_name'] ? this.userDetails['first_name'] : '',
            lastName: this.userDetails['last_name'] ? this.userDetails['last_name'] : '',
            company_name: this.userDetails['company_name'] ? this.userDetails['company_name'] : '',
            contact_no: this.userDetails['phone_no'] ? this.userDetails['phone_no'] : '',
            additional_contact_no: this.userDetails['additional_phone'] ? this.userDetails['additional_phone'] : '',
            address: this.userDetails['address'] ? this.userDetails['address'] : ''
          })
        }
      }, (error) => {
        // submit loading
        this.isLoadingOne = false;
        // print the error on console
        console.error(error);
      }));
  }

  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // unsubscriobe all the apis
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
