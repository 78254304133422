import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class ViewTemplateService {

  viewDetails(url , dataParams : any): Observable<{}> {
    return this.http.get(url , {
      params : dataParams
    });
  }

  constructor(private http: HttpClient) {}
}
