import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { LayoutComponent } from './layout/layout.component';
import { WelcomeComponent } from './_common/welcome/welcome.component'
import { DashboardComponent } from './dashboard/dashboard.component';
// import { ViewNotificationComponent } from './view-notification/view-notification.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { AuthGuard } from './_guards';
import { RegisterComponent } from './register/register.component';
import { TmsQuoteComponent } from './tms-quote/tms-quote.component';
import { EmailTruckerComponent } from './email-trucker/email-trucker.component';
import { TruckerCostComponent } from './trucker-cost/trucker-cost.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { LcltermsconditionComponent } from './lcltermscondition/lcltermscondition.component';
import { TmstermsconditionComponent } from './tmstermscondition/tmstermscondition.component';
import { EmailTruckerResultComponent } from './email-trucker-result/email-trucker-result.component';



const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: {
      title: 'Login'
    },
  },
  {
    path: 'register',
    component: RegisterComponent,
    data: {
      title: 'Register'
    },
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    data: {
      title: 'Forgot Password'
    },
  },
  {
    path: 'trucker-cost',
    component: TruckerCostComponent,
    data: {
      title: 'Trucker Cost'
    },
  },
  {
    path: 'thankyou',
    component: ThankyouComponent,
    data: {
      title: 'Thank you'
    },
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent,
    data: {
      title: 'Reset Password'
    },
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'export-import',
        redirectTo: 'export-import',
        pathMatch: 'full',
      },
      // {
      //   path: 'home',
      //   component: DashboardComponent,
      //   data: {
      //     breadcrumb: 'Dashboard',
      //     title: 'Home'
      //   },
      // },
      {
        path: 'export-import',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./export-import/export-import.module').then(mod => mod.ExportImportModule)
      },
      {
        path: 'lcltermscondition',
        data: {
          // breadcrumb: 'Export Import Listing'
          title: 'LCL Terms & Conditions'
        },
        component:LcltermsconditionComponent
      },
      {
        path: 'tmstermscondition',
        data: {
          title: 'TMS Terms & Conditions'
          // breadcrumb: 'Export Import Listing'
        },
        component:TmstermsconditionComponent
      },
      {
        path: 'email-trucker-result',
        data: {
          title: 'Email Trucker Results'
          // breadcrumb: 'Export Import Listing'
        },
        component:EmailTruckerResultComponent
      },
      {
        path: 'dashboard',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./cust-dashboard/cust-dasboard.module').then(mod => mod.CustDasboardModule)
      },
      {
        path: 'customerpo',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./customerpo/customerpo.module').then(mod => mod.CustomerpoModule)
      },
      {
        path: 'pohistory',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./pohistory/pohistory.module').then(mod => mod.PohistoryModule)
      },
      {
        path: 'poactive',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./poactive/poactive.module').then(mod => mod.PoActiveModule)
      },
      {
        path: 'podetail',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./po-detail/po-detail.module').then(mod => mod.PoDetailModule)
      },
      {
        path: 'shipment-details',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./shipment-details/shipment-details.module').then(mod => mod.ShipmentDetailsModule)
      },
      {
        path: 'edit-export-import',
        data: {
          // breadcrumb: 'Export Import Listing'
        },
        loadChildren: () => import('./edit-export-import/edit-export-import.module').then(mod => mod.EditExportImportModule)
      },
      {
        path: 'air-import-quote',
        loadChildren: () => import('./air-import-quote/air-import-quote.module').then(mod => mod.AirImportQuoteModule)
      },
      {
        path: 'air-import-quote-history',
        loadChildren: () => import('./air-import-quote-history/air-import-quote-history.module').then(mod => mod.AirImportQuoteHistoryModule)
      },
      {
        path: 'air-import-booked-quote-history',
        loadChildren: () => import('./air-import-quote-history/air-import-quote-history.module').then(mod => mod.AirImportQuoteHistoryModule)
      },
      {
        path: 'air-import-quote-detail',
        canActivate: [AuthGuard],
        loadChildren: () => import('./air-import-quote-detail/air-import-quote-detail.module').then(mod => mod.AirImportQuoteDetailModule)
      },
      {
        path: 'airport-export-history',
        loadChildren: () => import('./airport-export-history/airport-export-history.module').then(mod => mod.AirportExportHistoryModule)
      },
      {
        path: 'airport-export-history-booking',
        loadChildren: () => import('./airport-export-history-booking/airport-export-history-booking.module').then(mod => mod.AirportExportHistoryBookingModule)
      },
      {
        path: 'airport-import-history-booking',
        loadChildren: () => import('./airport-import-history-booking/airport-import-history-booking.module').then(mod => mod.AirportImportHistoryBookingModule)
      },
      {
        path: 'air-export-quote',
        loadChildren: () => import('./air-export-quote/air-export-quote.module').then(mod => mod.AirExportQuoteModule)
      },
      {
        path: 'air-export-quote-history',
        loadChildren: () => import('./air-export-quote-history/air-export-quote-history.module').then(mod => mod.AirExportQuoteHistoryModule)
      },
      {
        path: 'air-export-quote-list',
        loadChildren: () => import('./air-export-quote-list/air-export-quote-list.module').then(mod => mod.AirExportQuoteListModule)
      },
      {
        path: 'air-booked-shipment',
        loadChildren: () => import('./air-booked-shipment-listing/air-booked-shipment-listing.module').then(mod => mod.AirBookedShipmentListingModule)
      },
      {
        path: 'air-export-quote-detail',
        canActivate: [AuthGuard],
        loadChildren: () => import('./air-export-quote-detail/air-export-quote-detail.module').then(mod => mod.AirExportQuoteDetailModule)
      },
      {
        path: 'quote-history',
        data: {
          // breadcrumb: 'Quote History Listing'
        },
        loadChildren: () => import('./quote-history/quote-history.module').then(mod => mod.QuoteHistoryModule)
      },
      {
        path: 'book-quote',
        data: {
          // breadcrumb: 'Quote History Listing'
        },
        loadChildren: () => import('./book-quote/book-quote.module').then(mod => mod.BookQuoteModule)
      },
      {
        path: 'book-tmsquote',
        data: {
          // breadcrumb: 'Quote History Listing'
        },
        loadChildren: () => import('./book-tmsquote/book-tmsquote.module').then(mod => mod.BookTMSQuoteModule)
      },
      {
        path: 'tms-quote',
        canActivate: [AuthGuard],
        data: {
          // breadcrumb: 'Quote History Listing'
        },
        loadChildren: () => import('./tms-quote/tms-quote.module').then(mod => mod.TMSModule)
      },
      {
        path: 'edit-tms-quote',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./edit-tms-quote/edit-tms-quote.module').then(mod => mod.EditTmsQuoteModule)
      },
      {
        path: 'quote-detail',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./quote-detail/quote-detail.module').then(mod => mod.CustomerProfileModule)
      },
      {
        path: 'email-trucker',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./email-trucker/email-trucker.module').then(mod => mod.EmailTruckertModule)
      },
      {
        path: 'edit-email-trucker',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./edit-email-trucker/edit-email-trucker.module').then(mod => mod.EditEmailTruckerModule)
      },
      {
        path: 'email-trucker-history',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./trucker-email-history/trucker-email-history.module').then(mod => mod.TruckerEmailHistoryModule)
      },
      {
        path: 'tms-quote-history',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./tms-quote-history/tms-quote-history.module').then(mod => mod.TmsQuoteHistoryModule)
      },
      {
        path: 'tms-booked-quote',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./tms-booked-quote/tms-booked-quote.module').then(mod => mod.TmsQuoteBookedModule)
      },
      {
        path: 'lcl-booked-quote',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./lcl-booked-quotes/lcl-booked-quotes.module').then(mod => mod.LclBookedQuotesModule)
      },
      {
        path: 'tms-quote-details',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./tms-quote-details/tms-quote-details.module').then(mod => mod.TmsQuoteDetailsModule)
      },

      // new module added
      {
        path: 'trucker-email-history',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./trucker-email-history/trucker-email-history.module').then(mod => mod.TruckerEmailHistoryModule)
      },
      {
        path: 'trucker-email-details',
        data: {
          // breadcrumb: ''
        },
        loadChildren: () => import('./trucker-email-details/trucker-email-details.module').then(mod => mod.TruckerEmailDetailsModule)
      },

      {
        path: 'change-password',
        component: ChangePasswordComponent,
        data: {
          // breadcrumb: 'Change Password',
          title: 'Change Password'
        },
      },
      {
        path: 'edit-profile',
        component: EditProfileComponent,
        data: {
          // breadcrumb: 'Edit Profile',
          title: 'Edit Profile'
        },
      },
      {
        path: 'welcome',
        data: {
          // breadcrumb: 'Role Management',
          title: 'Welcome'
        },
        component: WelcomeComponent
      },
      {
        path: '**',
        // path: 'export-import',
        redirectTo: 'export-import',
      }
    ]
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
