import { environment } from '../../environments/environment';

export const URLS = Object({
  'forgotPassword': `${environment.apiUrl}/customer/forgetpassword`,
  'changePassword': `${environment.apiUrl}/customer/resetpassword`,
  'register': `${environment.apiUrl}/customer/register`,
  'login': `${environment.apiUrl}/customer/login`,
  'logout': `${environment.apiUrl}/customer/logout`,
  'expireLink': `${environment.apiUrl}/customer/custpwdlinkexpire`,
  'cfslisting': `${environment.apiUrl}/master/cfs/list`,
  'createcfs': `${environment.apiUrl}/master/cfs/create`,
  'editcfs': `${environment.apiUrl}/master/cfs/update`,
  'userListing': `${environment.apiUrl}/user/listing`,
  'userDetails': `${environment.apiUrl}/user/details`,
  'profileDetails': `${environment.apiUrl}/customer/details`,
  'updateProfile': `${environment.apiUrl}/customer/update`,
  'getMenu': `${environment.apiUrl}/customer/menu`,
  'updateUser': `${environment.apiUrl}/user/update`,
  'addUser': `${environment.apiUrl}/user/register`,
  'updateAdminUserStatus': `${environment.apiUrl}/user/status`,
  'cfstocfslisting': `${environment.apiUrl}/master/cfstocfs/list`,
  'destinationList': `${environment.apiUrl}/exports/destination`,
  'originList': `${environment.apiUrl}/exports/origin`,
  'getDoorQuote': `${environment.apiUrl}/exports/pickupdoor/getquote`,
  'getQuote': `${environment.apiUrl}/exports/getquote`,
  'getQuoteList': `${environment.apiUrl}/exports/quotelist`,
  'getQuoteHist': `${environment.apiUrl}/exports/quotedetails`,
  'quoteConfirm': `${environment.apiUrl}/exports/quoteconfirm`,
  'originByPin': `${environment.apiUrl}/exports/originbypin`,
  'editQuote': `${environment.apiUrl}/exports/edit`,
  'getRate': `${environment.apiUrl}/rate/card`,
  'thirdPartyAPI': `${environment.apiUrl}/customer/cargostar`,
  'exceptions': `${environment.apiUrl}/exports/exceptionrule`,
  'allRegion': `${environment.apiUrl}/allregion`,
  'regionDestination': `${environment.apiUrl}/allregion/destination`,
  'regionCFS': `${environment.apiUrl}/ratecard/cfslist`,
  'cargoCfs': `${environment.apiUrl}/cargostar/cfs`,
  'cargoZips': `${environment.apiUrl}/trucker/getzip`,
  'cargoAirport': `${environment.apiUrl}/cargostar/airport`,
  'tmsgenerate': `${environment.apiUrl}/cargostar/alldomesticapis`,
  'tmsQuoteGenerate': `${environment.apiUrl}/cargostar/tms/generateQuote`,
  'tmsQuoteSearchLfs': `${environment.apiUrl}/cargostar/tms/quotesearchlfs`,
  'tmsQuoteSearchExfreight': `${environment.apiUrl}/cargostar/tms/quotesearchexfreight`,
  'tmsQuoteSearchshiprite': `${environment.apiUrl}/cargostar/tms/quotesearchshiprite`,

  'quoteDetails': `${environment.apiUrl}/cargostar/quote/details`,
  'priceDetails': `${environment.apiUrl}/cargostar/quote/pricedetails`,
  'menuDetail': `${environment.apiUrl}/customer/menu`,

  // new url add for new module (i.e tms history and detail)
  'quoteList': `${environment.apiUrl}/cargostar/quote/list`,
  'TruckerEmailList': `${environment.apiUrl}/customer/truckerhistory/list`,
  'TruckerEmailDetails': `${environment.apiUrl}/customer/truckerhistory/details`,
  'bookTMS': `${environment.apiUrl}/cargostar/quote/book`,

  //trucker email
  'getEmails': `${environment.apiUrl}/customer/trucker/list`,
  'addTrucker': `${environment.apiUrl}/customer/truckercost/request`,
  'getTrucker': `${environment.apiUrl}/trucker/truckerquote/details`,
  'addTruckerCost': `${environment.apiUrl}/trucker/cost/submit`,
  'fetchTrucker': `${environment.apiUrl}/customer/trucker/domestic`,
  'vendorList': `${environment.apiUrl}/cargostar/tmsCompanies`,


  //cut of time
  'cutOff': `${environment.apiUrl}/cutofftime`,
  'getVesselBook': `${environment.apiUrl}/exports/vesselschedule`,

  //dashboard
  'shipmentList': `${environment.apiUrl}/wtg/shipmentlist`,
  'allShipmentList': `${environment.apiUrl}/wtg/map/shipmentdata`,
  'containerList': `${environment.apiUrl}/wtg/local/shipmentdata`,
  'setALert': `${environment.apiUrl}/wtg/shipment/alert`,
  'shipmentFilter': `${environment.apiUrl}/wtg/shipmentfilter`,
  'mapFilter': `${environment.apiUrl}/wtg/map`,
  'searchPreference': `${environment.apiUrl}/wtg/searchpreference`,
  'getSearchPreference': `${environment.apiUrl}/getsearchpreference`,
  'shipmentDetailsDownload': `${environment.apiUrl}/getShipmentDocuments`,


  //thirdparty api
  'getcontainers': `${environment.apiUrl}/wtg/shipmenttrack`,


  //PO
  'polist': `${environment.apiUrl}/po/list`,
  'poOrigin': `${environment.apiUrl}/po/origin`,
  'poExpoOrigin': `${environment.apiUrl}/po/exportorigin`,
  'poDestination': `${environment.apiUrl}/po/destination`,
  'poAdd': `${environment.apiUrl}/po/add`,
  'poDetails': `${environment.apiUrl}/po/details`,


  //Consolidated Center
  'getConsolidationCenter': `${environment.apiUrl}/exports/getConsolidationCenter`,

  //Select Truckers
  'quoteUpdate': `${environment.apiUrl}/exports/quoteUpdate`,

  //household good
  'householdGoods': `${environment.apiUrl}/commodities/list`,

  //Trucker Reminder
  'reminderTrucker': `${environment.apiUrl}/truckerhistory/sendReminder`,

  'remindAdmin': `${environment.apiUrl}/truckerChargeFailure/remindAdmin`,
  
  //Air Export
  'airportOrigin': `${environment.apiUrl}/v1/customer/air-cargo-origins`,
  'airportDestination': `${environment.apiUrl}/v1/customer/air-cargo-destinations`,
  'airCommodities': `${environment.apiUrl}/v1/customer/air-cargo-commodities`,

  //Air quote history
  'airQuoteHistory': `${environment.apiUrl}/v1/customer/air-cargo-quote-request`,
  //Air export
  'airExportQuote': `${environment.apiUrl}/v1/customer/air-cargo-quote-request-export`,
  'airExportQuoteAutoBook': `${environment.apiUrl}/v1/customer/air-cargo-quote-request-export/book/`,
  'quoteGenration': `${environment.apiUrl}/v1/customer/air-cargo-quote-request-export`,
  'socketConnection': `${environment.apiUrl}`,
  'nearestAirportZipCode' : `${environment.apiUrl}/v1/customer/nearest_airport_by_zipcode`,
  //Air import
  'airImportQuote': `${environment.apiUrl}/v1/customer/air-cargo-quote-request-import`,
  'bookImportQuote': `${environment.apiUrl}/v1/customer/air-cargo-quote-request-import/book/`,
})

export const SECRET_KEY: string = '$2a$10$e.oPc.dyrwRoQCpDvO9Rhe';
