import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ResetPasswordService {

    private expireLink: string = URLS.expireLink
    private forgetPassword: string = URLS.forgotPassword;
    
    private updatedExpireLink: string;
    private updatedForgetPassword: string;

    constructor(private http: HttpClient, private activeRoute: ActivatedRoute) {
        this.activeRoute.queryParams.subscribe(params => {
            const isCustomer = Object.keys(params)[0];
            if (isCustomer === 'user_token') {
                // this.updatedExpireLink = this.expireLink.replace('customer', 'user');
                this.updatedExpireLink = this.expireLink;
                this.updatedForgetPassword = this.forgetPassword;
                // this.updatedForgetPassword = this.forgetPassword.replace('customer', 'user');
            } else {
                this.updatedExpireLink = this.expireLink;
                this.updatedForgetPassword = this.forgetPassword;
            }
        });
    }


    checkExpire(dataParams : any): Observable<{}> {
        return this.http.get(`${this.updatedExpireLink}` , {
            params : dataParams    
        });
    }

    forgotPassword(dataParams : any): Observable<{}> {
        return this.http.post(`${this.updatedForgetPassword}`, dataParams);
    }
}
