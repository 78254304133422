import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router,
    private authervice: AuthenticationService) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      
      let isLoggedIn;
      this.authervice.isLoggedIn().subscribe(
        (data) => {
          isLoggedIn = data;
          // console.log(state.url);
          
        }
        );
    if (isLoggedIn) {
      // let checkUrl = JSON.parse(localStorage.getItem('checkUrl'));
      // let checkData = JSON.parse(localStorage.getItem('routes'));
      // console.log(checkData);
      // if(checkData.isLcl && !checkData.isCargostar){
      //   this.router.navigate(['/export-import']);
      //   return false;
      // }
      // if(!checkData.isLcl && checkData.isCargostar){
      //   this.router.navigate(['/tms-quote']);
      //   return false;
      // }
      // if(checkData.isLcl && checkData.isCargostar){
      //   this.router.navigate(['/export-import']);
      //   return false;
      // }
       // authorised so return true
      return true;
    }
    if (state.url != '/') {
      localStorage.setItem('returnUrl', state.url) 
    }
     // not logged in so redirect to login page with the return url
    state.url != '/' ?  this.router.navigate(['login'], { queryParams: { returnUrl: state.url } }) : this.router.navigate(['login']);
    return false;
  }
}
