import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as crypto from 'crypto-js';
import { URLS, SECRET_KEY } from '../_config/api.config';
import { CookieService } from 'ngx-cookie-service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  // secret key to encrypt the login credentails
  private secretKey: string = SECRET_KEY;

  // observable user details
  // public currentUserSubject: BehaviorSubject<any>;

  // onserveable logged in status
  public loggedInStatus;

  // logged in user tpe
  // public currentUser: Observable<any>;

  // login api url
  private loginUrl = URLS.login;

  // Register api url
  private registerUrl = URLS.register;

  // logout api url
  private logoutUrl = URLS.logout;
  constructor(private http: HttpClient, private cookieService: CookieService) {
    // this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.loggedInStatus = new BehaviorSubject<boolean>(this.hasToken());
    this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }


  /**
* if we have token the user is loggedIn
* @returns {boolean}
*/
  private hasToken(): boolean {
    // return !!localStorage.getItem('token');
    return !!this.cookieService.get('token');
  }



  /**
  *
  * @returns {Observable<T>}
  */
  public isLoggedIn() {
    return this.loggedInStatus.asObservable();
  }


  public getBwrowserDetails() {
    let browserName = navigator.userAgent, OSName = navigator.appVersion;

    if (browserName.indexOf("Firefox") > -1) { browserName = "Mozilla Firefox"; }
    else if (browserName.indexOf("Chrome") > -1) { browserName = "Google Chrome"; }
    else if (browserName.indexOf("Safari") > -1) { browserName = "Safari"; }
    else { browserName = "unknown"; }

    // if (OSName.indexOf("Win") != -1) OSName = "Windows";
    // if (OSName.indexOf("Mac") != -1) OSName = "MacOS";
    // if (OSName.indexOf("X11") != -1) OSName = "UNIX";
    // if (OSName.indexOf("Linux") != -1) OSName = "Linux";
    let tempStartOfString = navigator.appVersion.indexOf('(') + 1;
    let tempEndOfString = navigator.appVersion.indexOf(')');
    OSName = navigator.appVersion.slice(tempStartOfString, tempEndOfString);
    return { browserName: browserName, OSName: OSName }
  }
  /**
*  Login the user then tell all the subscribers about the new status
*/
  public login(loginData: any) {
    const browserInfo = this.getBwrowserDetails();
    const cipherData = crypto.AES.encrypt(JSON.stringify(loginData), this.secretKey).toString();
    // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
    return this.http.post<any>(`${this.loginUrl}`, { 'data': cipherData }, {
      headers: new HttpHeaders({
        "platform": browserInfo['browserName'] + '-' + browserInfo['OSName']
      })
    })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.code === 200) {
          // localStorage.setItem('token', user.data);
          if (user.data != undefined) this.cookieService.set('token', user.data);
          if (user.customer_type) this.cookieService.set('customer_type', user.customer_type);
          this.loggedInStatus.next(true);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //  localStorage.setItem('currentUser', JSON.stringify(user));
          //  this.currentUserSubject.next(user);
          return user;
        } else if ((user && user.code === 405) || (user && user.code === 204)) {
          return user;
        } else {
          return false;
        }
      }));
  }

  /**
*  Register the user then tell all the subscribers about the new status
*/
  public register(registerData: any) {
    const browserInfo = this.getBwrowserDetails();
    const cipherData = crypto.AES.encrypt(JSON.stringify(registerData), this.secretKey).toString();
    // const cipherPasswrd = crypto.AES.encrypt(password, this.secretKey).toString();
    return this.http.post<any>(`${this.registerUrl}`, { 'data': cipherData }, {
      headers: new HttpHeaders({
        "platform": browserInfo['browserName'] + '-' + browserInfo['OSName']
      })
    })
      .pipe(map(user => {
        // login successful if there's a jwt token in the response
        if (user && user.code === 200) {
          // localStorage.setItem('token', user.data);
          // this.cookieService.set('token', user.data);
          this.loggedInStatus.next(true);
          // store user details and jwt token in local storage to keep user logged in between page refreshes
          //  localStorage.setItem('currentUser', JSON.stringify(user));
          //  this.currentUserSubject.next(user);
          return user;
        } else if (user && user.code === 405) {
          return user;
        } else {
          return false;
        }
      }));
  }

  public logout() {
    this.cookieService.delete('customer_type');
    this.loggedInStatus.next(false);
    return this.http.get<any>(`${this.logoutUrl}`);
    // remove user from local storage to log user out
    //  localStorage.removeItem('currentUser');
    //  this.currentUserSubject.next(null);
  }
}
