import { Component, Input, OnChanges, OnInit, OnDestroy } from '@angular/core';
import { ColumnMap } from '../layout.model';
import { NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { TableLayoutService } from './table-layout.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { CommonService } from '../../_services/common.service';

@Component({
  selector: 'app-table-layout',
  templateUrl: './table-layout.component.html',
  styleUrls: ['./table-layout.component.scss'],
  providers: [TableLayoutService],
  animations: [
    trigger('openClose' , [
      state('open', style({
        opacity: 1,
        padding: '24px',
        maxHeight: '800px'
      })),
      state('closed', style({
        opacity: 0,
        padding: 0,
        maxHeight: 0,
        overflow:'hidden'
      })),
      transition('open => closed', [
        animate('300ms ease-in')
      ]),
      transition('closed => open', [
        animate('300ms ease-out')
      ]),
    ])
  ]
})
export class TableLayoutComponent implements OnInit, OnChanges, OnDestroy {
  @Input() settings: any[];
  @Input() apiUrl: any;
  @Input() actions: any;
  @Input() widthConfig: any[];
  @Input() scrollConfig: any;
  @Input() search: any[];
  @Input() queryParams: any[] = [];
  @Input() addModule: any;
  @Input() pageName: any;
  fixedHeader: Boolean = true;
  multiOptions: any;
  // column key
  columnMaps: ColumnMap[];

  // filter panel initial state
  isOpen = false;

  /**
   * filter panel toggle function
   * */
  toggle() {
    this.isOpen = !this.isOpen;
  }

  // managing subscription
  private subscription: Subscription = new Subscription();

  // confirmation modal instance
  private confirmModal: NzModalRef;

  // table default params
  public tableConfig = {
    'page': 1,
    'limit': 10
  };

  // table data loading status
  public loading = false;

  // array to hold the table data
  public listOfAllData: any[] = [];

  // object to store custom table configuration
  public tableDataConfig = {};

  //form instance
  public validateForm: FormGroup;

  /**role aceess information */
  public page_access = {};

  public autocompleteData: any = [];
  
  constructor(
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private tableLayoutService: TableLayoutService,
    private fb: FormBuilder,
    private notification: NzNotificationService,
    private modal: NzModalService,
    private activeRoute: ActivatedRoute,
    private commonService: CommonService
  ) { }
  /**
   * function to implemnt sorting on table
   * @param key to hold the table header key
   * @param value to hold the table sorting type
   */
  public sort(sort: { key: string; value: string }): void {
    // preparing dynamic params based on sorting
    this.tableConfig['sort_by'] = sort.key;
    this.tableConfig['sort_type'] = sort.value === 'descend' ? 'desc' : 'asc';

    // if no results found then don't call the api
    if (this.listOfAllData.length) {
      this.getData(true);
    }
  }

  /**
//    * function to reset the form
//    */
  public resetForm(): void {
    // reset the form
    this.validateForm.reset();
    // if reset set the page to 1
    this.tableConfig['page'] = 1;
    // list all data
    this.getData(true);
  }

  /**
   * function to naivgate to detail pages
   * @param viewName to hold the router link
   * @param dataSource to hold the router params
   */
  public navigateRow(viewName: string, dataSource: number): void {
    this.router.navigate([viewName, dataSource], { relativeTo: this.activeRoute });
  }

  public onNotify(data) {
    let httpParams = {
      id: data['page_id']
    }
    if (data['assignment_title']) {
      httpParams['is_assignment'] = true;
    } else {
      httpParams['is_poll'] = true;
    }
    this.loading = true;
    this.subscription.add(this.tableLayoutService.notify(this.apiUrl.notify, httpParams)
    .subscribe((res) => {
      this.loading = false;
      if (res['code'] === 200) {
        this.notification.success('success', 'Notification sent successfully.');
        this.getData(true);
      }
    }, (error) => {
      this.loading = false;
    }))
  }

  public onPublish(data) {
    let httpParams = {
      id: data['page_id'],
      is_published: 1
    }
    if (data['assignment_title']) {
      httpParams['is_assignment'] = true;
    } else {
      httpParams['is_poll'] = true;
    }
    this.loading = true;
    this.subscription.add(this.tableLayoutService.publish(this.apiUrl.publish, httpParams)
    .subscribe((res) => {
      this.loading = false;
      if (res['code'] === 200) {
        this.notification.success('success', 'Published successfully.');
        this.getData(true);
      }
    }, (error) => {
      this.loading = false;
    }))
  }

  // // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  // /**
  //  * function to update the status
  //  * @param data hold the table data instance
  //  */
  public updateTable(data) {
    // params send to change status
    let httpParams : {[k: string] : any} = {};
    httpParams[this.queryParams['status']] = data['status'] ? 0 : 1;
    httpParams[this.queryParams['page_id']] = data['page_id'];
    // status loading  indicator
    data['switchLoading'] = true;

    // table data loading status
    this.loading = true;
    // api call to update the status
    this.subscription.add(this.tableLayoutService.updateStatus(this.apiUrl.updateStatus, httpParams).subscribe((res) => {
      // if success then go in loop
      if (res['code'] === 200) {
        // change status
        data['status'] = !data['status'];

        // success modal when status updated
        this.notification.create(
          'success',
          'Status changed',
          'Status has been updated successfully.'
        );
      }

      // destroy the confirm modal instance
      this.confirmModal.destroy();

      // status loading  indicator
      data['switchLoading'] = false;

      // table data loading status
      this.loading = false;
    }, (error) => {

      // destroy the confirm modal instance
      this.confirmModal.destroy();

      // status loading  indicator
      data['switchLoading'] = false;

      // table data loading status
      this.loading = false;

      // print the error on console
      console.error(error);
    }));
  }

  /**
   * function to confirm the status
   * @param data table data instance
   */
  public showConfirm(data: any): void {
    // only for Discount page if disable then return
    if(this.pageName === 'discountCode' && !data['status']){
      return;
    }
    
    this.confirmModal = this.modal.confirm({
      // ${data.is_active === 0 ? 'Inactive' : 'Active'}
      nzTitle: `Do you want to change the status?`,
      nzOnOk: () =>
        new Promise((resolve, reject) => {
          // function call to update the status
          this.updateTable(data);
        }).catch(() => { console.log('Oops errors!') }),
      nzOnCancel: () => { this.loading = false; data['switchLoading'] = false; }
    });
  }

  ngOnInit(): void {
      this.breakpointObserver
      .observe(['(max-width: 576px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.fixedHeader = false;
        } else {
          this.fixedHeader = true;
        }
      });

    // function call to load data when page loads
    this.getData(true);
    this.commonService.eventObservable.subscribe(response => {
      if (response['event'] === 'page_access') {
        this.page_access = JSON.parse(response['data']);
      };
      if (response['event'] === 'domainAdded') {
        this.getData(true);
      }
    })
  }

  public getAutocompleteData(value: string) {
    let param = {
      dropdown: true,
      leader_name: value
    };
    // api call to get the table data
    this.subscription.add(this.tableLayoutService
      .getAutocompleteData(this.apiUrl.autocomplete ,param)
      .subscribe((responce: any) => {
        if (responce['code'] === 200) {
          this.autocompleteData = responce['data'];
        }
      })
    )
  }

  public formatDate(dates) {
    dates.forEach((date, index) => {
      if (typeof date !== 'string') {
        const dd = (date.getDate().toString().length === 1) ? '0' + date.getDate() : date.getDate();
        let mm = date.getMonth() + 1;
        mm = (mm.toString().length === 1) ? '0' + mm : mm;
        const yyyy = date.getFullYear();
        dates[index] = yyyy + '-' + mm + '-' + dd;
      }
    });
  }

  /**
   *
   * @param fromFilter If user search from advance filter do not pass page number
   */
  public getData(fromFilter: boolean): void {
    // httpParams for handling dynamic query params
    let httpParams = new HttpParams();

    let copyOfTableConfig = this.tableConfig;
    // query params for pagination
    if(!fromFilter) copyOfTableConfig['page'] = 1;
    for (const key of Object.keys(copyOfTableConfig)) {
      httpParams = httpParams.append(key, copyOfTableConfig[key]);
    }
    // get form keys and value
    const formKeys = Object.keys(this.f),
      formValue = this.f,
      searchBy = [],
      searchValue = [];

    // create query params based on form value
    for (let keys of formKeys) {
      const appendValues = formValue[keys]['value'];
      if (appendValues) {
        if (keys.includes('date') === true) {
          this.formatDate(appendValues);
        }
        searchBy.push(keys);
        if (typeof appendValues === 'string') {
          searchValue.push(appendValues.trim().replace(/,/g,'#x2c'));
        } else {
          searchValue.push(appendValues);
        }
      }
    }
    if(searchBy.length) httpParams = httpParams.append('search_by', searchBy.toString());
    if(searchValue.length) httpParams = httpParams.append('search', searchValue.toString());

    // table data loading status
    this.loading = true;
    // api call to get the table data
    this.subscription.add(this.tableLayoutService.getTableData(this.apiUrl.list, httpParams).subscribe((res) => {
      // if data present then only goes in the ;oop
      if (res['code'] === 200) {
        // displaying data in table
        this.listOfAllData = res['data'];
        // for Discount page only
        // if (this.pageName === 'discountCode') {
        //   for(const data of this.listOfAllData) {
        //     if(data['is_active'])
        //   }
        // }
        for (const data of this.listOfAllData) {
          data['page_id'] = data[this.queryParams['page_id']];
          data['status'] = data[this.queryParams['status']];
        }
        // storing the totalrecord for pagination
        this.tableDataConfig['totalRecords'] = res['data']['totalRecords'];
      }
      // table data loading status
      this.loading = false;
    }, (error) => {
      // table data loading status
      this.loading = false;
      // print the error on console
      console.error(error);
    }));

  }


  // To redirect on add page.
  public redirectAdd(): void {
    this.router.navigate([this.addModule.url]);
  }

  ngOnChanges() {
    if (this.settings) {
      this.columnMaps = this.settings.map(col => new ColumnMap(col));
    } else {
      this.columnMaps = Object.keys(this.listOfAllData[0]).map(key => {
        return new ColumnMap({ primaryKey: key });
      });
    }
    // prepare form group
    this.validateForm = this.fb.group({});
    if (this.search) {
      for (let i = 0; i < this.search.length; i++) {
        this.validateForm.addControl(this.search[i].fieldKey, new FormControl());
      }
    }
  }


  deleteData(dataId: any): void {
      //todo
  }
  // on destroy unsubscribe all the subscription
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
