import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

import { AuthenticationService } from '../_services';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService,
      private cookieService: CookieService,
      private activeRoute: ActivatedRoute,
      private router:Router) {


      this.activeRoute.queryParams.subscribe(params => {
        const customerKey = Object.keys(params)[0];
        if (customerKey === 'customer_token') {
          const staticToken = '3fc20699-9fb3-4900-9277-4cc654a65414';
          this.cookieService.set('token', staticToken);
          console.log(staticToken, '  staticToken');
        }
      });
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        // const currentUser = this.authService.currentUserValue;
        // console.log(currentUser);
        // const token = localStorage.getItem('token');
        const token = this.cookieService.get('token');
        const dashShipmentToken = "39df253b-9518-429d-a532-55272ee30ed3";
        const headers = {};
        if (token) {
          headers['Authorization'] = token;
          headers['Opentrack-API-Key'] = dashShipmentToken;
        }
        else headers['Authorization'] = '3fc20699-9fb3-4900-9277-4cc654a65414';
          headers['Cache-Control'] = 'no-cache',
          headers['isadminusers'] = true;
          headers['Pragma'] = 'no-cache';
          headers['language_code'] = 'en';
          headers['usertype'] = 'customer';
        //   headers['Content-Type'] = 'application/json';
            request = request.clone({
                setHeaders: headers
            });



        return next.handle(request);
    }
}
