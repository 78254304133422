
import { NgModule } from '@angular/core';
import { DynamicBreacrumbComponent } from './dynamic-breacrumb.component';
import { SharedModule } from '../_common/shared.module';


@NgModule({
  declarations: [
    DynamicBreacrumbComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    DynamicBreacrumbComponent
  ]
})
export class DynamicBreacrumbModule { }
