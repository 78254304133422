import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ResetPasswordService } from './reset-password.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { SECRET_KEY } from '../_config/api.config';
import * as crypto from 'crypto-js';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-reset-password',
  providers: [ResetPasswordService],
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  validateForm: FormGroup;

  // password visibility toggle
  public passwordVisible = false;
  public newpasswordVisible = false;

  // Checking Link expire from Mobile
  public linkExpireMobile: boolean = false;

  // Checking token is valid or not
  public tokenValid: boolean = true;

  // Getting token from URL
  private customer_token: string;

  //Showing loading on button and disablind button during API call
  public isLoading: boolean = false;

  // managing subscription
  private subscription: Subscription = new Subscription();

  // secret key to encrypt the login credentails
  private secretKey: string = SECRET_KEY;

  // checking whether customer or user
  private isCustomer: string;

  public validation_messages = {
    'password': [
      { type: 'required', message: 'Please input your password!' },
      { type: 'minlength', message: 'Your Password must be at least 8 characters long' },
      { type: 'maxlength', message: 'Your Password can' + "'" + 't be more than 12 characters' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, one number and one special character' },
      { type: 'whitespace', message: 'Please enter valid password!' },
      { type: 'quotes', message: 'Please enter valid password!' },
    ]
  }

  // For redirecting on login page
  public webSuccess: boolean = false;

  // For mobile success
  public mobileSuccess: boolean = false;

  public hideForm: boolean = true;

  constructor(private fb: FormBuilder,
    private _location: Location,
    private activeRoute: ActivatedRoute,
    private resetPasswordService: ResetPasswordService,
    private notification: NzNotificationService,
    private cookieService: CookieService) {

    this.activeRoute.queryParams.subscribe(params => {
      this.isCustomer = Object.keys(params)[0];
      if (this.isCustomer === "customer_token") {
        this.customer_token = params.customer_token;
      } else if (this.isCustomer === "user_token") {
        this.customer_token = params.user_token;
      }
    });
    this.validateForm = this.fb.group({
      password: ['', [
        Validators.minLength(8),
        Validators.maxLength(12),
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')]],
      confirm: ['', [this.confirmValidator]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.validateForm.controls; }

  ngOnInit(): void {
    this.checkingIsExpire();
  }

  linkExpire: boolean = false;
  submitForm = ($event: any, value: any) => {
    $event.preventDefault();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    }
    this.resetingPassword();
  };

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      this.validateForm.controls[key].markAsPristine();
      this.validateForm.controls[key].updateValueAndValidity();
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() => this.validateForm.controls.confirm.updateValueAndValidity());
  }

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  onBack() {
    this._location.back();
  }

  private checkingIsExpire(): void {
    this.isLoading = true;
    // params send to change status
    const params = {};
    if (this.isCustomer === "customer_token") {
      params['customer_token'] = this.customer_token;
    } else {
      params['user_token'] = this.customer_token;
    }
    // api call to update the status
    this.subscription.add(this.resetPasswordService
      .checkExpire(params)
      .subscribe((response: any) => {
        // if success then go in loop
        if (response['code'] === 200) {
          if(response['data']['invalidUser'] == true) {
              this.tokenValid = false;
              this.hideForm = false;
          } else if (response['data']['isExpired']) {
            this.notification.error(
              'Link Expired',
              'The Link has been expired, Please send link again!'
            );
            this.hideForm = false;
            if (this.isCustomer === "customer_token") {
              this.linkExpireMobile = true;
            } else {
              this.linkExpire = true;
            }
          } else {
            this.linkExpireMobile = false;
            this.linkExpire = false;
          }
        }
        this.isLoading = false;

      }, (error) => {
        // print the error on console
        console.error(error);
        this.linkExpireMobile = false;
      }));
  }


  private resetingPassword(): void {

    // params send to change status
    const params = {
      'password': this.f.password.value
    };

    this.isLoading = true;
    const cipherData = crypto.AES.encrypt(JSON.stringify(params), this.secretKey).toString();
    const dataObj = {
      'data': cipherData
    };
    if (this.isCustomer === "customer_token") {
      dataObj['customer_token'] = this.customer_token;
    } else {
      dataObj['user_token'] = this.customer_token;
    }
    // api call to update the status
    this.subscription.add(this.resetPasswordService
      .forgotPassword(dataObj)
      .subscribe((response: any) => {
        // if success then go in loop
        if (response['code'] === 200) {
          // success modal when status updated
          this.notification.success(
            'Success',
            'Password has been reseted successfully!'
          );
          this.hideForm = false;
          // loading status
          this.isLoading = false;
          this.cookieService.delete('token');
          if (this.isCustomer === "customer_token") {
            this.mobileSuccess = true;
          } else {
            this.webSuccess = true;
          }

        }
      }, (error) => {
        // loading status
        this.isLoading = false;
        // print the error on console
        console.error(error);
      }));
  }

  // on destroy unsubscribe all the subscription
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
