import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class EditProfileService {
  getDetails = URLS.profileDetails;
  updateProfile = URLS.updateProfile;

  updateUserProfile(updatedParams : any): Observable<{}> {
    return this.http.put(`${this.updateProfile}` , updatedParams);
  }
  getUserProfileDetails(): Observable<{}> {
    return this.http.get(`${this.getDetails}`);
  }

  constructor(private http: HttpClient) {}
}
