import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-email-trucker-result',
  templateUrl: './email-trucker-result.component.html',
  styleUrls: ['./email-trucker-result.component.scss']
})
export class EmailTruckerResultComponent implements OnInit {
  isReminded:boolean = true;
  constructor(
    public router: Router,
    public activeRoute: ActivatedRoute,
  ) { }

  ngOnInit() {
  }

  gotoHistory(){
    this.router.navigate(['/email-trucker-history']);  
  }

}
