import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class ChangePasswordService {
  updatePassword = URLS.changePassword;

  updateEmail(updatedParams : any): Observable<{}> {
    return this.http.post(`${this.updatePassword}` , updatedParams);
  }

  constructor(private http: HttpClient) {}
}
