import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Role } from './_models/role';
import { AuthenticationService } from './_services';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Some Title';
  currentUser;
  constructor(private router: Router, private titleService: Title,
    private authService:AuthenticationService) {
      this.authService.currentUser.subscribe(x => this.currentUser = x);
    this.router.events.subscribe(
      (event: any) => {
        if (event instanceof NavigationEnd) {
          const title = this.getTitle(router.routerState, router.routerState.root).join('-');
          // this.titleService.setTitle(title + ' | Fx-Admin');
          this.titleService.setTitle(title + ' | Customer');
          window.scrollTo(0, 0);
        }
      });
  }


  get isAdmin() {
    return this.currentUser && this.currentUser.role === Role.Admin;
}
  public getTitle(state, parent) {
    const data = [];
    if (parent && parent.snapshot.data && parent.snapshot.data.title) {
      data.push(parent.snapshot.data.title);
    }

    if (state && parent) {
      data.push(... this.getTitle(state, state.firstChild(parent)));
    }
    return data;
  }

}
