import { Component, OnInit, OnChanges, OnDestroy, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ViewTemplateService } from './view-template.service';
import { HttpParams } from '@angular/common/http';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-template',
  providers: [ViewTemplateService],
  templateUrl: './view-template.component.html'
})
export class ViewTemplateComponent implements OnInit, OnChanges, OnDestroy {
  @Input() listData: any[] = [];
  @Input() apiUrl: string;
  @Input() routeUrl: string;
  @Input() queryParams: string;
  @Input() dynamicCol: any = {};
  
  // managing subscription
  private subscription: Subscription = new Subscription();

  // query params instance
  public dataId: string;

  // data instace to display
  public displayData:any={};

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private viewTemplateService: ViewTemplateService
  ) {
    // get id from the query parms
    this.dataId = this.activeRoute.snapshot.paramMap.get('id');
   }

  ngOnInit() {
    // fetch data based on query params
    this.getData();
  }

  /**
   * function to display the details of specific id
   */
  private getData(): void {

    // httpParams for handling dynamic query params
    let httpParams = new HttpParams();


    // query params for pagination
    httpParams = httpParams.append(this.queryParams, this.dataId);

    // api call to get the table data
    this.subscription.add(this.viewTemplateService
      .viewDetails(this.apiUrl, httpParams)
      .subscribe((data: any) => {
        // if success then go in loop
        if (data['code'] === 200) {

          // displaying data
          this.displayData = data.data[0];
          console.log(this.displayData);
          
          // //logic to manipulate field keys for queryparams
          // if(this.displayData['email_content']) {
          //   const val = this.displayData['email_content'];
          //   delete  this.displayData['email_content'];
          //   this.displayData['content'] = val;
          // }
          // if(this.displayData['cms_description']) {
          //   this.displayData['content'] = this.displayData['cms_description'];
          //   delete  this.displayData['cms_description'];
          // }
          if (this.displayData['menus'].length) {
            this.displayData['menus'] = this.displayData['menus'].map((obj) => obj.menu_name.substr(0,1).toLocaleUpperCase() + obj.menu_name.substr(1)).join(', ');
          }
        }
      }, (error) => {

        // print the error on console
        console.error(error);
      }));
  }

  ngOnChanges() {
      
  }


  // go to listing page
  public goBack() {
    this.router.navigate([this.routeUrl]);
  }

  // on destroy unsubscribe all the subscription
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
