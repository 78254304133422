import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class TruckerCostService {
  getDetails = URLS.getTrucker;
  addCost = URLS.addTruckerCost;

  constructor(private http: HttpClient) {}

  getTruckerDetails(dataParams : any): Observable<{}> {
    return this.http.post(`${this.getDetails}` , dataParams);
  }

  addTruckerCost(dataParams : any): Observable<{}> {
    return this.http.post(`${this.addCost}` , dataParams);
  }

}
