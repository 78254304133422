import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { AuthenticationService } from '../_services/authentication.service';
import { CustomValidator } from '../_common/custom-validators';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  // loading status
  public isLoadingOne = false;


  // password visibility toggle
  public passwordVisible = false;
  public newpasswordVisible = false;

  // passwor filed instance
  public password: string;

  // form instance
  public registerForm: FormGroup;

  // submit form stauts
  public submitted = false;

  // preparing validation message for form field
  public validation_messages = {
    'userName': [
      { type: 'required', message: 'Please enter your username.' },
      { type: 'invalidEmail', message: 'Please enter valid email.' },
      { type: 'whitespace', message: 'Invalid username' },
    ],
    'password': [
      { type: 'required', message: 'Please enter your Password.' },
      { type: 'minlength', message: 'Your Password must be at least 8 characters long.' },
      { type: 'maxlength', message: 'Your Password cannot exceed more than 20 characters long.' },
      { type: 'pattern', message: 'Your password must contain at least one uppercase, one lowercase, one number and one special character' }
    ],
    'confirm': [
      { type: 'required', message: 'Please confirm your password.' },
      { type: 'confirm', message: 'Password is inconsistent.' }
    ],
    'firstName': [
      { type: 'required', message: 'Please enter your first name.' },
      { type: 'minlength', message: 'First Name must be at least 3 characters long.' },
      { type: 'maxlength', message: 'First Name should not be more than 40 characters long.' },
      { type: 'whitespace', message: 'Invalid first name' },
    ],
    'lastName': [
      { type: 'required', message: 'Please enter your last name.' },
      { type: 'minlength', message: 'Last Name must be at least 3 characters long.' },
      { type: 'maxlength', message: 'Last Name should not be more than 40 characters long.' },
      { type: 'whitespace', message: 'Invalid last name' },
    ],
    'userEmail': [
      { type: 'required', message: 'Please enter your email address.' },
      { type: 'invalidEmail', message: 'Please enter valid email address.' },
      { type: 'whitespace', message: 'Invalid email address' },
    ],
    'companyName': [
      { type: 'required', message: 'Please enter company name.' },
      { type: 'maxlength', message: 'Company name should not be more than 40 characters.' },
      { type: 'minlength', message: 'Company name should be more than 2 characters.' },
      { type: 'whitespace', message: 'Invalid company name.' },
      { type: 'quotes', message: 'Please enter valid company name.' },
    ],
    'cellno': [
      { type: 'required', message: 'Please enter phone no.' },
      { type: 'maxlength', message: 'Phone no should not be more than 10 characters long.' },
      { type: 'whitespace', message: 'Invalid Phone no.' },
      { type: 'quotes', message: 'Please enter valid phone no.' },
      { type: 'pattern', message: 'Please enter valid phone no.' },
    ],
  }

  //showing the register part;
  public showRegister: any = false;


  constructor(private fb: FormBuilder, private router: Router,
    private notification: NzNotificationService,
    private activeRoute: ActivatedRoute,
    private cookieService: CookieService,
    private authService: AuthenticationService) {
    // redirect to home if already logged in
    // redirect to home if already logged in
    //   if (this.authService.currentUserValue) {
    //     this.router.navigate(['/']);
    // }
    let isLoggedIn;
    this.authService.isLoggedIn().subscribe(
      (data) => {
        isLoggedIn = data;
      }
    );
    // if (isLoggedIn) {
    //   this.router.navigate(['/export-import']);
    // }
  }

  // registerClick(event) {
  //   event.preventDefault();
  //   event.stopPropagation();
  //   this.showRegister = !this.showRegister
  // }

  ngOnInit(): void {

    this.registerForm = this.fb.group({
      firstName: [null,
        {
          validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator,
          // Validators.minLength(3),
          Validators.maxLength(40)]),
          //updateOn: 'blur'
        }],
      lastName: [null,
        {
          validators: Validators.compose([Validators.required, CustomValidator.noWhiteSpaceValidator,
          // Validators.minLength(3),
          Validators.maxLength(40)]),
          //updateOn: 'blur'
        }],
      userEmail: [null,
        {
          validators: Validators.compose([Validators.required,
          // CustomValidator.noWhiteSpaceValidator,
          CustomValidator.emailValidator]),
          //updateOn: 'blur'
        }
      ],
      companyName: [null,
        {
          validators: Validators.compose([Validators.required,
          Validators.maxLength(40),
          Validators.minLength(3),
          CustomValidator.noWhiteSpaceValidator,]),
          //updateOn: 'blur'
        }],
      password: [null,
        {
          validators: Validators.compose([
            Validators.minLength(8),
            Validators.maxLength(20),
            Validators.required,
            Validators.pattern('^(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$')]),
          //updateOn: 'blur'
        }],
      confirm: new FormControl(null, { validators: this.confirmValidator }),
      cellno: [null,
        {
          validators: Validators.compose([
            Validators.required,
            // CustomValidator.noWhiteSpaceValidator,
            Validators.maxLength(10),
            Validators.pattern('[0-9]{10}')]),
          //updateOn: 'blur'
        }],
      // remember: [true]
    });
  }

  // // convenience getter for easy access to form fields
  // get f() { return this.validateForm.controls; }

  // convenience getter for easy access to form fields
  get f2() { return this.registerForm.controls; }

  // confirm password validator
  public confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.f2.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };


  public emptypassword() {
    this.registerForm.patchValue({
      confirm: ''
    })
  }

  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  /**
   * function to dubmit the form
   */
  public submitForm() {

    // if (!this.showRegister) {
    //   const cookieValue = this.cookieService.get('token');
    //   if (cookieValue) this.cookieService.delete('token');

    //   for (const i in this.f) {
    //     this.f[i].markAsDirty();
    //     this.f[i].updateValueAndValidity();
    //   }
    //   this.submitted = true;
    //   // stop here if form is invalid
    //   if (this.validateForm.invalid) {
    //     return;
    //   }

    //   // login params
    //   const params = {
    //     'email': this.f.userName.value,
    //     'password': this.f.password.value
    //   }
    //   // loading status
    //   this.isLoadingOne = true;
    //   this.authService.login(params)
    //     .subscribe(
    //       res => {
    //         if (res && res['code'] === 200) {
    //           if(res['data']) {
    //             // navigatin url which is stored
    //             this.router.navigateByUrl(this.returnUrl);

    //             // succesfull popup
    //             this.notification.create('Success',
    //               `Congrats`,
    //               'You have been logged in suceesfully..'
    //             );
    //             this.validateForm.reset();
    //           } else {
    //             // succesfull popup
    //             this.notification.info('Info',
    //               res['message']
    //             );
    //           }
    //         } else if (res['code'] === 405) {
    //           this.notification.error(
    //             'Error',
    //             res['message']
    //           );
    //         } else if (res['code'] === 204) {
    //           this.notification.error(
    //             'Error',
    //             res['message']
    //           );
    //         }

    //         // loading status
    //         this.isLoadingOne = false;
    //       },
    //       error => {

    //         // loading status
    //         this.isLoadingOne = false;

    //         // print the error to console
    //         console.error(error);
    //       });
    // } else {

    for (const i in this.f2) {
      this.f2[i].markAsDirty();
      this.f2[i].updateValueAndValidity();
    }
    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }

    // login params
    const params = {
      'first_name': this.f2.firstName.value,
      'last_name': this.f2.lastName.value,
      'email': this.f2.userEmail.value,
      'password': this.f2.password.value,
      'phone_no': this.f2.cellno.value,
      'company_name': this.f2.companyName.value
    }
    // loading status
    this.isLoadingOne = true;

    this.authService.register(params)
      .subscribe(
        res => {
          if (res && res['code'] === 200) {
            this.cookieService.deleteAll();
            // navigatin url which is stored
            // location.href = '/login';

            // succesfull popup
            this.notification.create('success',
              `Congrats`,
              'You have been registered suceesfully..'
            );
            setTimeout(() => {
              location.href = '/login';
            }, 1500);
            this.registerForm.reset();
            this.showRegister = false;
          } else if (res['code'] === 405) {
            this.notification.error(
              'Error',
              res['message']
            );
          }

          // loading status
          this.isLoadingOne = false;
        },
        error => {

          // loading status
          this.isLoadingOne = false;

          // print the error to console
          console.error(error);
        });
  }
}

// }
