import { CanDeactivate } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { HostListener, Injectable } from '@angular/core';
import { NzModalService  } from 'ng-zorro-antd';
import { Subject } from 'rxjs';

export interface FormComponent {
  validateForm: FormGroup;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<FormComponent> {

  constructor(private modal: NzModalService) {

  }
  canDeactivate(component?: FormComponent) {
    // if (component.validateForm.dirty)   return confirm('You have unsaved changes. Are you sure you want to navigate away?');
    // return true
    if(component.validateForm.dirty) {
      this.modal.confirm({
        nzTitle: 'Confirm',
        nzContent: 'You have unsaved changes. Are you sure you want to leave?',
        nzOkText: 'Stay',
        nzCancelText: 'Leave',
      })
      return false
    } else {
      return true;
    }
    return true;
  }


    // @HostListener allows us to also guard against browser refresh, close, etc.
  // @HostListener('window:beforeunload', ['$event'])
  // unloadNotification($event: any) {
  //   if (!this.canDeactivate()) {
  //       $event.returnValue = "This message is displayed to the user in IE and Edge when they navigate without using Angular routing (type another URL/close the browser/etc)";
  //   }
  // }
}
