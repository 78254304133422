import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../_services';
import { CookieService } from 'ngx-cookie-service';
import { NzNotificationService } from 'ng-zorro-antd';

// export interface User {
//   id: number;
//   username: string;
//   password: string;
//   firstName: string;
//   lastName: string;
//   token: string;
// }
const userList = ['U', 'Lucy', 'Tom', 'Edwardfdf'];

@Component({
  selector: 'app-user-settings-popup',
  templateUrl: './user-settings-popup.component.html'
})
export class UserSettingsPopupComponent {
  // isLoggedIn: boolean = true;
  // public currentUser: string;
  text: string = userList[3];


  clickMe(routerLink): void {
    this.router.navigate([routerLink]);
  }
  constructor(private router: Router,
    private notification: NzNotificationService,
    private authService: AuthenticationService, private cookieService:CookieService) {
      // this.authService.currentUser.subscribe(x => this.currentUser = x);
  }

  logout() {
    this.authService.logout().subscribe( (data) => {
      if (data['data'] && data['code'] === 200) {
        this.cookieService.delete('token');
        this.router.navigate(['/login']);
        localStorage.clear();
      }
    },
      (err) => {
      console.error(err);
      }
    );
}
}
