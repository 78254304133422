import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLS } from '../_config/api.config';

@Injectable ()
export class LayoutService {
    constructor (private http: HttpClient) {}

    getMenusData(param?) {
        return this.http.get(URLS.getMenu, {params: param});
    }
}
