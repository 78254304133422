import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { URLS } from '../_config/api.config';

@Injectable()
export class ForgotPasswordService {
  forgotPassword = URLS.forgotPassword;

  forgotPass(dataParams : any): Observable<{}> {
    return this.http.post(`${this.forgotPassword}` , dataParams);
  }

  constructor(private http: HttpClient) {}
}
