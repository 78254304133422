import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd';
import { CookieService } from 'ngx-cookie-service';
import { SECRET_KEY } from '../_config/api.config';
import * as crypto from 'crypto-js';
import { TruckerCostService } from './trucker-cost.service';
import { Subscription } from 'rxjs';
import { differenceInCalendarDays } from 'date-fns';

@Component({
  selector: 'app-trucker-cost',
  templateUrl: './trucker-cost.component.html',
  styleUrls: ['./trucker-cost.component.scss'],
  providers: [TruckerCostService]
})
export class TruckerCostComponent implements OnInit {
  @ViewChild('htmlData', { static: false }) htmlData: ElementRef;
  private subscription: Subscription = new Subscription();
  today = new Date();
  disabledDate = (current: Date): boolean => {
    // Can not select days before today and today
    return differenceInCalendarDays(current, this.today) < 0;
  };
  public tableLoad = false;
  public quoteId: string;
  recordExist = true;
  quoteResponseData = [];
  bookingDetails = [];
  breakdownData = [];
  quote_price = [];
  isBooked = true;
  cost: any;
  additional_comments: any;
  public isData: any;
  public hidemyAccess: boolean = true;
  public data: any;
  // secret key to encrypt the login credentails
  private secretKey: string = SECRET_KEY;
  /**
      * Static data to show quote details
      */
  public cargoData = [
    {
      key: 'trucking_enquiry_identity',
      name: 'Trucking Inquiry ID',
      value: 0,
      show: true,
    },
    {
      key: 'originport',
      name: 'Origin zip code',
      value: '',
      show: true,
    },
    {
      key: 'destinationPort',
      name: 'Destination zip code',
      value: ''
    },
    {
      key: 'name',
      name: 'First Name',
      value: '',
      show: true,
    },
    {
      key: 'email',
      name: 'Email ID',
      value: '',
      show: true,
    },
    {
      key: 'grand_total_weight',
      name: 'Total weight',
      value: '',
      show: false,
    }

    // {
    //   key: 'breakdown_detail',
    //   name: 'Breakdown Detail',
    //   value: ''
    // },
    // {
    //   key: 'breakdown_amount',
    //   name: 'Breakdown Amount',
    //   value: 0
    // }

  ];

  public truckerType = [
    {
      key: 'full_truck_load_no',
      name: 'Number of full truck loads',
      value: ''
    },
    {
      key: 'total_weight',
      name: 'Total weight',
      value: ''
    },
    {
      key: 'package_no',
      name: 'Package/Pallete no',
      value: ''
    },
    {
      key: 'commodity',
      name: 'Commodity',
      value: ''
    },
    {
      key: 'is_hazardous',
      name: 'Hazardous',
      value: ''
    },
    {
      key: 'pickup_date',
      name: 'Pickup date',
      value: ''
    },
  ];
  validDate: any;
  public packageData = [];
  constructor(
    private activeRoute: ActivatedRoute,
    private notification: NzNotificationService,
    private cookieService: CookieService,
    public truckerService: TruckerCostService,
    public router: Router,
  ) {
    this.activeRoute.queryParams.subscribe(params => {
      this.isData = Object.keys(params)[0];
      if (this.isData === "data") {
        this.data = params.data;
        // console.log(this.data);
      }
    });
  }

  ngOnInit() {
    this.getPageDetails();
  }


  getPageDetails() {
    const params = {
      data: this.data
    }
    this.subscription.add(
      this.truckerService.getTruckerDetails(params).subscribe((res: any) => {
        if (res['code'] == 200) {
          this.notification.success('Success', res['message']);
          this.quoteResponseData = res['data'][0];
          console.log(this.quoteResponseData);
          if (this.quoteResponseData['cost']) {
            this.hidemyAccess = this.quoteResponseData['cost'] ? false : true;
          }
          if (this.quoteResponseData['valid_date']) {
            console.log(this.quoteResponseData['valid_date']);

            this.validDate = this.quoteResponseData['valid_date'];
          }
          // this.breakdownData = this.quoteResponseData['breakdown_data'];
          if (this.quoteResponseData['is_booked']) {
            this.cost = this.quoteResponseData['cost'];
            this.additional_comments = this.quoteResponseData['additional_comment'];
            this.isBooked = true;
          }
          else {
            this.isBooked = false;
          }

          let sumOfWegh = 0;

          this.truckerType.forEach(ele => {
            if (res['data'][0]['trucker_type'] == 'FTL') {
              if (ele['key'] === 'full_truck_load_no') {
                ele['value'] = res['data'][0]['full_truck_load_no'];
              }
              if (ele['key'] === 'total_weight') {
                let weight: any = this.quoteResponseData['total_weight'].match(/(\d+)/)
                weight = weight ? (Number(weight[0])).toFixed(2) : this.quoteResponseData['total_weight']
                let unit = ele['key'].includes('lbs') ? 'lbs' : 'kg';
                // ele['value'] = res['data'][0]['total_weight'];
                ele['value'] = weight + unit;
              }
              if (ele['key'] === 'package_no') {
                ele['value'] = res['data'][0]['package_no'];
              }
              if (ele['key'] === 'commodity') {
                ele['value'] = res['data'][0]['commodity'];
              }
              if (res['data'][0]['is_hazardous']) {
                if (ele['key'] === 'is_hazardous') {
                  ele['value'] = "Yes";
                }
              }
              else {
                if (ele['key'] === 'is_hazardous') {
                  ele['value'] = "No";
                }
              }
              if (ele['key'] === 'pickup_date') {
                ele['value'] = res['data'][0]['pickup_date'];
              }
            }
          });
          //getting package details from package details
          let unitOfWeight: any;
          if (this.quoteResponseData['trucking_package']) {
            this.quoteResponseData['trucking_package'].forEach(element => {
              if (element['weight'] >= 0) {
                element['weight'] = element['weight'] ? element['weight'].toFixed(2) : null;
              }
              if (element['unit_of_weight'] === 'lbs') {
                element['weight_in_kg'] = (Number(element['weight']) * 0.453592).toString();
              } else {
                element['weight_in_kg'] = element['weight'];
              }
              unitOfWeight = element['unit_of_weight']
              this.packageData.push(element);
            });
          }

          //set cargo data details
          this.cargoData.forEach(element => {
            // console.log(element);

            // let accessor = [];

            if (element['key'] === 'trucking_enquiry_identity') {
              element['value'] = res['data'][0]['trucking_enquiry_identity'];
            }
            if (element['key'] === 'originport') {
              element['value'] = res['data'][0]['origin_zip_code'] + ', ' + res['data'][0]['origin_cfs'] + ', ' + res['data'][0]['origin_state'];
            }
            if (element['key'] === 'destinationPort') {
              element['value'] = res['data'][0]['dest_zip_code'] + ', ' + res['data'][0]['destination'] + ', ' + res['data'][0]['dest_state'];
            }
            if (element['key'] === 'name') {
              element['value'] = res['data'][0]['first_name'] + ' ' + res['data'][0]['last_name'];
            }
            if (element['key'] === 'email') {
              element['value'] = res['data'][0]['email'];
            }
            if (this.quoteResponseData['is_totalgrossweight'] == 1) {
              if (element['key'] === 'grand_total_weight') {
                let weight: any = this.quoteResponseData['grand_total_weight'] ? Number(this.quoteResponseData['grand_total_weight']).toFixed(2) : 0;
                element['value'] = weight + " (" + unitOfWeight + ")";
                element.show = true;
              }
            }

            // if (element['key'] === 'carrier_scac') {
            //   element['value'] = response['data'][0]['carrier_scac'];
            // }
            // if (element['key'] === 'carrier_name') {
            //   element['value'] = response['data'][0]['carrier_name'];
            // }
            // if (element['key'] === 'carrier_scac') {
            //   element['value'] = response['data'][0]['carrier_scac'];
            // }
            // if (element['key'] === 'markup_total') {
            //   element['value'] = response['data'][0]['markup_total'];
            // }
            // if (element['key'] === 'broker_source') {
            //   element['value'] = response['data'][0]['broker_source'];
            // }
            // if (element['key'] === 'rating') {
            //   element['value'] = response['data'][0]['rating'];
            // }
            // if (element['key'] === 'tsa_approved') {
            //   element['value'] = response['data'][0]['is_tsa_approved'];
            // }
            // if (element['key'] === 'accessorials') {
            //   element['value'] = accessor.join(', ');
            // }
            // if (element['key'] === 'breakdown_detail') {
            //   element['value'] = response['data'][0]['breakdown_description'];
            // }
            // if (element['key'] === 'breakdown_amount') {
            //   element['value'] = response['data'][0]['breakdown_amount'];
            // }

          });



          if (this.quoteResponseData['cost']) {
            this.cost = this.quoteResponseData['cost'];
          }
          if (this.quoteResponseData['additional_comment']) {
            this.additional_comments = this.quoteResponseData['additional_comment'];
          }

          // //getting booking details
          // if(this.quoteResponseData['booking_details']){
          //   this.bookingDetails = this.quoteResponseData['booking_details'][0];
          // }

          // //getting Quote price details
          // if(this.quoteResponseData['quote_prices']){
          //   this.quote_price = this.quoteResponseData['quote_prices'];
          // }



        }
        else {
          this.notification.error('Error', res['message']);
        }
      },
        (err) => {
          console.log(err);
        })
    );
  }

  costSubmit() {
    if (this.cost == undefined || this.cost == null || this.cost.trim() == '') {
      this.notification.error('Error', 'Cost cannot be empty');
      this.cost = null;
      return;
    }
    if (this.validDate == undefined || this.validDate == null || this.validDate == '') {
      this.notification.error('Error', 'Valid Till Date cannot be empty');
      return;
    }
    const params = {
      "data": this.data,
      "cost": +this.cost,
      "valid_date": this.validDate,
    }
    if (this.additional_comments) {
      params['additional_comment'] = this.additional_comments;
    }
    this.subscription.add(
      this.truckerService.addTruckerCost(params).subscribe((res: any) => {
        if (res['code'] == 200) {
          this.notification.success('Success', 'Thank you for submitting trucking cost');
          this.router.navigate(['/thankyou']);
        }
        else {
          this.notification.error('Error', res['message']);
        }
      },
        (err) => {
          console.log(err);
        }));
  }

}
