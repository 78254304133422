import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable () 
export class CommonService {
    public event: any = {};
    public eventObservable = new BehaviorSubject('');
    constructor() {
    }

    public setEvent(event: string, data: any) {
        this.event = {'event': event, 'data': data};
        this.eventObservable.next(this.event);
    }


  public isNumber(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }


decimalCheck(event) {
    var charCode = (event.which) ? event.which : event.keyCode
    if (charCode > 31 && (charCode < 48 || charCode > 57) && !(charCode == 46 || charCode == 8)) {
        return false;
    }
    if (event.key == '.' && event.target.value.includes(event.key)) {
        return false
    }
    var dotPos = event.target.value.indexOf(".");
    if (event.target.value.includes('.') && dotPos == event.target.value.length - 3) {
        return false
    }
    return true;
  }

  omitSpecialChar(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }

  omitChar(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57))
      return false;

    return true;
  }

  numberCheck(event) {
    var charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
  }
}